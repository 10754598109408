import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useEffect } from "react";

let icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    height="20px"
    viewBox="0 0 24 24"
    width="20px"
    fill="#5985E1"
  >
    <rect fill="none" height="24" width="24" />
    <path d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z" />
  </svg>
);
const CardPrice= ({ title, users, price, period, theme ="light" , planType="UpGarde" }) => {
  return (
    <CardContent className="flex justify-center flex-col  items-center">
      {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
      </Typography> */}

      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 20,
        }}
        className="pt-2 sm:pt-7 pb-3   dark:text-gray-600"
        variant="h5"
        component="div"
      >
        {title}
      </Typography>
      <Typography className="py-1   dark:text-gray-600" variant="body2">
        {users}
      </Typography>

      <div className="flex py-4 justify-center  items-center w-full  dark:text-gray-600">
        <span className="text-2xl">${price[period].title}</span>
        <span className="mt-1"> &nbsp; per month</span>
      </div>
      <Button variant="contained" size="small" className="w-full pt-2">
      <span className="text-sm p-px">{planType}</span>
        {/* <Link
          to={`https://accounts.clikkle.com/login?referrer=https://www.clikkle.com/hr&&redirectto=https://hr.clikkle.com?amount=${price[period].amount}&period=${price[period].period}&theme=${theme}`}
        >
          <span className="text-sm p-px">{planType}</span>
        </Link> */}
      </Button>

      <div className="text-left pt-3 font-medium  px-1 w-full  dark:text-gray-600">
        All Features Included
      </div>
      <div className="flex flex-col text-xs text-gray-700 center mt-1 p-1  dark:text-gray-600">
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Full HR platform</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span> Recruiting / ATS</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Time off Management</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>HR Analytics</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Time and Attendance Tracking</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Performance Management</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>New Hire Onboarding</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Job Offers</span>
        </p>

        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Surveys and Training</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>24/7 Customer Support</span>
        </p>
      </div>
      <div className="py-6 w-8 h-15"></div>
    </CardContent>
  );
};

const ClikkleCardPrice = ({ price, period, theme ="light" }) => {
  return (
    <CardContent className="flex justify-center flex-col  items-center">
      <Typography
        sx={{
          display: "inline",
          fontWeight: "bold",
          fontSize: 20,
        }}
        className=" pb-2 dark:text-gray-600"
        variant="h5"
        component="div"
      >
        Clikkle <span className="text-blue-500">+</span>
      </Typography>
      <Typography className="py-1 dark:text-gray-600" variant="body2">
        Unlimited Users
      </Typography>

      <div className="flex py-4 justify-center  items-center w-full dark:text-gray-600">
        <span className="text-2xl">${price[period].title}{" "}</span>
        <span className="mt-1"> &nbsp; per month</span>
      </div>
      <Button variant="contained" size="small" className="w-full pt-2">
      <span className="text-sm p-px ">UpGrade</span>
        {/* <Link
          to={`https://accounts.clikkle.com/number-of-employee?referrer=https://www.clikkle.com/hr&&redirectto=https://hr.clikkle.com?amount=${price[period].amount}&period=${price[period].period}&theme=${theme}`}
        >
         
        </Link> */}
      </Button>

      <div className="text-left pt-3 font-medium  px-1 w-full dark:text-gray-600">
        All Features Included
      </div>
      <div className="flex flex-col text-xs w-56 text-gray-700 center mt-1 p-1 dark:text-gray-600">
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span className="font-medium">
            Unlimited Users & Previous Plans Features
          </span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Access to all Clikkle Products</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Customizable Reports and Analytics</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Real Time Notifications and Alerts</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Document Management System</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Mobile App Access</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Automated Payroll Processing</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Employee Self-Service Portal</span>
        </p>

        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>AI Powered Candidate Matching</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Integrated Video Interviewing</span>
        </p>
        <p className="flex items-center my-2">
          <span className="mr-4">{icon}</span>
          <span>Project Management Tools</span>
        </p>
      </div>
      <div className="py-6 w-8 h-15"></div>
    </CardContent>
  );
};

const plans = {
  private: {
    title: "Private",
    users: "1 - 5 Users",
    price: {
      monthly: { period: "mo", amount: 49 ,  title : 49  },
      annually: { period: "an", amount: 294 ,  title : "24.50" },
    },
  },
  business: {
    title: "Business",
    users: "6 - 50 Users",
    price: {
      monthly: { period: "mo", amount: 149 ,  title : 149},
      annually: { period: "an", amount: 894,  title : "74.50" },
    },
  },
  enterprise: {
    title: "Enterprise",
    users: "Unlimited Users",
    price: {
      monthly: { period: "mo", amount: 249 ,  title : 249},
      annually: { period: "an", amount: 1494 ,  title : "124.50" },
    },
  },
  cikklePlus: {
    monthly: { period: "mo", amount: 99 ,  title : 99},
    annually: { period: "an", amount: 594 ,  title : "49.50"},
  },
};

const HrSubscription = () => {
  const [alignment, setAlignment] = React.useState("monthly");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const borderStyle = {
    marginTop: "18px",
    width: "18px",
    height: "18px",
    borderTop: "2px solid blue", // Adjust the color and width as needed
    borderRight: "2px solid blue", // Adjust the color and width as needed
    borderTopRightRadius: "50px",
  };

  const handleChange = (
    event,
    newAlignment
  ) => {
    setAlignment(newAlignment);
    event.preventDefault();
  };
  useEffect(() => {
    const element = document.getElementById('top-section'); // Replace 'top-section' with your element id
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <div id="top-section" className="flex flex-col py-4 justify-center  items-center">
      {/* <div className="flex flex-row">
        <div className="text-center m-2 text-blue-800">Save Up to 50%</div>
        <div style={borderStyle}></div>
      </div> */}

      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          sx={{
            px: {
              xs: 2,
              sm: 3,
            },
            fontSize: {
              xs: 14,
              sm: 18,
            },
            borderBottomLeftRadius: 50,
            borderTopLeftRadius: 50,
          }}
          size="small"
          value="monthly"
        >
          Bill Monthly
        </ToggleButton>
        <ToggleButton
          sx={{
            px: {
              xs: 2,
              sm: 3,
            },
            fontSize: {
              xs: 14,
              sm: 18,
            },
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50,
          }}
          size="small"
          value="annually"
        >
          Bill Annually
        </ToggleButton>
      </ToggleButtonGroup>
      

      <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      my={2}
      py={4}
      gap={isSmallScreen ? 8 : 1}
    >
        <Card variant="outlined">
          <CardPrice
            title={plans.private.title}
            users={plans.private.users}
            price={plans.private.price}
            period={alignment}
            theme={theme}
            planType="Downgrade"
          />
        </Card>
        <Card variant="outlined">
          <CardPrice
            title={plans.business.title}
            users={plans.business.users}
            price={plans.business.price}
            period={alignment}
            theme={theme}
            planType="Cancel"
            
          />
        </Card>
        <Card variant="outlined">
          <CardPrice
            title={plans.enterprise.title}
            users={plans.enterprise.users}
            price={plans.enterprise.price}
            period={alignment}
            theme={theme}
            planType="UpGrade"
          />
        </Card>

        <Card variant="outlined">
          <div className="text-center py-1 font-medium text-white bg-blue-500  w-full">
            BEST VALUE
          </div>
          <ClikkleCardPrice price={plans.cikklePlus} period={alignment}   theme={theme}/>
        </Card>
      </Box>
    </div>
  );
};

export default HrSubscription;
