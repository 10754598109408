import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './utilities/axios';

import Header from './components/Header';
import HrSubscription from './pages/subscrpition/HrSubscription';
import Home from './pages/Home';
import Storage from './pages/Storage';
import PersonalInfo from './pages/PersnonalInfo';
import PaymentSubscrpition from './pages/PaymentSubscrpition';

const App = () => {
    return (
        <Header>
            <Routes>
                {/* <Route path='/' element={<Navigate to="/payment/subscription/hr" />} />
                <Route path="*" element={<Navigate to="/payment/subscription/hr" />} /> */}
                  <Route path='/' element={<Home />} /> 
                <Route path="/payment">
                    <Route path='subscription/hr' element={<HrSubscription />} />
                </Route>

                <Route path='/storage' element={<Storage />} />
                <Route path='/personal' element={<PersonalInfo />} />
                <Route path='/subscrpition' element={<PaymentSubscrpition />} />
               <Route path="*" element={<Navigate to="/" />} /> 

            </Routes>
        </Header>
    );
};

export default App;
