import React , {useState , useEffect} from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function HomeLooking() {

  const [isMobile, setIsMobile] = useState(false);
  console.log("page is opne")
  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);


  console.log("isMobile", isMobile);

  return (
    <>
    <div className="  rounded-lg mt-[22px] border" style={{ padding:'1px'}}>
      <div className=" mb-6 mt-[30px] ml-[15px]">
        <p className="md:text-[1.4rem] font-medium">Looking for something else?</p>
      </div>
      <div className="">
      <Accordion className='pt-[6px] pb-[6px]'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SearchIcon  className='mr-[4px]'/>   <span className='ml-2'> Search Clikkle Account</span> 
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion className='pt-[6px] pb-[6px]'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
      <HelpOutlineIcon  className='mr-[4px]'/>  <span className='ml-2'> Search help options</span> 
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion className='pt-[6px] pb-[6px]'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
   <FeedbackIcon  className='mr-[4px]'/>   <span className='ml-2'>Send feedback</span>
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion>
      </div>
   
    </div>
       <div className="mt-8 " style={{display:'flex' , flexDirection:'row'}}>
       <div className={isMobile ? 'w-full' : 'w-[60%]'}>
       <p className='pl-[10px]'>
         Only you can see your settings. You might also want to review your
         settings for E-sign, Campaign or whichever Clikkle services you use
         most. Clikkle keeps your data private, safe, and secure.{' '}
         <span className="text-blue-500">Learn more</span>
       </p>
        </div>
        {!isMobile  &&
        <div className='ml-[20px] mt-[-3px]'>
            <img src='/images/certified.svg'  className='w-[75px]' />
        </div>
}
   
     </div>
     </>

  );
}

export default HomeLooking;
