import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useState  , useEffect} from "react";
import ContactInfo from "../components/ContactInfo";
import AddressPersnolInfo from "../components/AddressPersnolInfo";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const PersonalInfo = () => {
  const [profileImage, setProfileImage] = useState("/images/user.svg");
  const [isMobile, setIsMobile] = useState(false);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  console.log("page is opne")
  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);


  console.log("isMobile", isMobile);


  return (
    <Box p={2}>
      <Grid >
      <div className="flex items-center justify-between md:w-full ">
           <Typography  
                                  style={{ fontSize: isMobile ? '1rem' : '1.5rem' , color:'#A5A5A5' }}

           >
            Personal Info
          </Typography>
          <div className="flex flex-row items-center justify-center gap-4">
           
            <InfoIcon />
          </div>
        </div>
       
      </Grid>
      <Grid className="mt-[2rem]">
        <p className="text-center mt-3 text-4xl">
          Personal Info
        </p>
        <p className="text-center mt-4 mb-4 text-lg">
          Info about you and your preferences across Clikkle services.
        </p>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 5 }}>
      <Grid container spacing={2}>
        {/* Image Grid */}
        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }} className="flex justify-center">
          <img
            src="/images/personalinfo.svg"
          

            className={`${isMobile ? 'w-[50%]' : 'w-[16.625rem]'} m-0 md:-m-10`}
            alt="Personal Info"
          />
        </Grid>

        {/* Text Grid */}
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <p className={`${isMobile ?'md:text-xl': 'text-[1.4rem]' } mb-4 font-medium`}>Your profile info in Clikkle services</p>
          <p className={`${isMobile ? 'w-full' : 'w-[80%]' } text-[1rem]` }>
            Personal info and options to manage it. You can make some of this info, 
            like your contact details, visible to others so they can reach you easily. 
            You can also see a summary of your profiles.
          </p>
        </Grid>
      </Grid>
    </Box>
        <Grid>
          <div className="rounded-lg mt-5 border">
            <div className=" pt-[15px] pb-[15px]">
            <p className="ml-2.5 mt-5 mb-1.5 md:text-[1.4rem] font-medium">
              Basic Info
            </p>
            <p className="ml-2.5">
              Some info may be visible to other people using Clikkle services. <a href="#">Learn more</a>
            </p>
            </div>
         
            <div className="flex justify-between ml-2.5 mr-2.5 mt-6  text-[0.9rem] mb-[8px]">
              <p>Profile Picture</p>
              <p>Add a profile picture to personalize your account</p>
              <div className={`relative ${!isMobile ?'w-12' :'w-16'} h-12 -top-2.5 rounded-full overflow-hidden border`}>
                <img src={profileImage} alt="Profile" className="w-full h-full object-cover" />
                <label htmlFor="upload-button" className="absolute top-5 right-0 bottom-0 left-3 bg-black bg-opacity-50 flex cursor-pointer">
                  <CameraAltIcon className="text-white" />
                </label>
                <input id="upload-button" type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
              </div>
            </div>
            <div className="flex justify-between border-t mt-3.75 mb-2.5 pt-[20px] pb-3   text-[0.9rem]">
              <p className="ml-2.5">Name</p>
              <p>Mathew Hayeddn</p>
              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:'15px'}} />
            </div>
          </div>
        </Grid>
        <Grid>
          <div className="rounded-lg mt-5 border">
          <div className=" pt-[15px] pb-[15px]">
            <p className="ml-2.5 mt-5 mb-1.5  md:text-[1.4rem] font-medium">
              Basic Info
            </p>
            <p className="ml-2.5">
              Some info may be visible to other people using Clikkle services. <a href="#">Learn more</a>
            </p>
            </div>
            <div className="flex justify-between ml-2.5 mr-2.5 mt-6  text-[0.9rem] mb-[8px]">
              <p>Profile Picture</p>
              <p>Add a profile picture to personalize your account</p>
              <div className={`relative ${!isMobile ?'w-12' :'w-16'} h-12 -top-2.5 rounded-full overflow-hidden border`}>
                <img src={profileImage} alt="Profile" className="w-full h-full object-cover" />
                <label htmlFor="upload-button" className="absolute top-5 right-0 bottom-0 left-3 bg-black bg-opacity-50 flex cursor-pointer">
                  <CameraAltIcon className="text-white" />
                </label>
                <input id="upload-button" type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
              </div>
            </div>
            <div className="flex justify-between border-t mt-3.75 mb-2.5 pt-[20px] pb-3  text-[0.9rem]">
              <p className="ml-2.5">Name</p>
              <p>Mathew Hayeddn</p>
              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:"15px"}} />
            </div>
            <div className="flex justify-between border-t mt-3.75 mb-2.5 pt-[20px] pb-3  text-[0.9rem]">
              <p className="ml-2.5">Birthday</p>
              <p>June 30 1995</p>
              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:"15px"}} />
            </div>
            <div className="flex justify-between border-t mt-3.75 mb-2.5 pt-[20px] pb-3  text-[0.9rem]">
              <p className="ml-2.5">Gender</p>
              <p>Male</p>
              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:"15px"}} />
            </div>
          </div>
        </Grid> 
         <ContactInfo/>
         <AddressPersnolInfo/>
      </Grid>
    </Box>
  );
};

export default PersonalInfo;
