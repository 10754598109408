import React , {useState ,useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

function PaymentSubscrpition() {
  const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    console.log("page is opne")
    useEffect(() => {
      const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
  
      checkIsMobile();
  
      window.addEventListener('resize', checkIsMobile);
  
      return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
  
  
    console.log("isMobile", isMobile);


  return (
   <>
       <Box p={2}>
      <Grid   >
        {/* <Grid item xs>
          <Typography variant="h5" color="text.primary" className="text-2xl">
            Home
          </Typography>
        </Grid>

        <Grid item>
          <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
            <InfoIcon />
          </IconButton>
        </Grid> */}
           <div className="flex items-center justify-between  md:w-full ">
           <Typography    
                       style={{ fontSize: isMobile ? '1rem' : '1.5rem' , color:'#A5A5A5' }}

>
           Payments & Subscriptions
          </Typography>
          <div className="flex flex-row items-center justify-center gap-4">
           
            <InfoIcon />
          </div>
        </div>
      </Grid>


      <Grid
  container
  justifyContent="center"
  alignItems="center"
  className="flex flex-col items-center mt-4"
  sx={{ flexDirection: "column", textAlign: "center" }}
>
  <Box className="m-auto">
    <Typography 
      className="text-3xl"
      sx={{ 
        fontSize: { xs: "1.5rem", sm: "2rem" },
        display: 'block' // Ensures it behaves as block element
      }}
    >
      <span className="welcome-text font-medium">Payments & Subscriptions</span>
    </Typography>
    <Typography                        style={{ fontSize: isMobile ? '14px' : '1rem' ,  marginTop:'5px' }}
    >
    Your payment info, transactions, recurring payments, and reservations
    </Typography>
  </Box>

  

</Grid>

<div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Payment methods</p>
              <p className='mt-[20px] ml-[15px]'>Payment methods are saved in your Clikkle Account so you can
              use them across Clikkle Services like Campaign or Ads.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='images/cardswip.svg' alt='Privacy Report Icon' className="w-full h-[230px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem] font-medium   text-nowrap	">Payment methods</p>
            <img src="/images/cardswip.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 mr-[-15px]"  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>


      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Payment methods</p>
              <p className='mt-[20px] ml-[15px] mb-[20px]'>Payment methods are saved in your Clikkle Account so you can
              use them across Clikkle Services like Campaign or Ads.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex ml-[15px] mr-[15px]'>  
            <p className="text-[1rem] font-medium  text-nowrap	">Privacy suggestions available</p>
           
           </div>
           <p className='text-base mt-4 mb-4  ml-[15px]'>
           Payment methods and transactions you’ve made using Clikkle pay.           </p>
       
           
         </div> }

          <div className="border-t border-t-1">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Purchases</p>
              <p className='mt-[10px] ml-[15px]'>Your transactions, encompassing deliveries and other 
              online purchases, conducted with any of our products.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='/images/cart.svg' alt='Privacy Report Icon' className="w-full h-[230px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
       
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem] font-medium  text-nowrap	">Privacy suggestions available</p>
            <img src="/images/cart.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 mr-[-10px]"  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-1">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage purchases</p>
          </div>
        </div>
        
      </div>


      
      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%] mt-[20px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Account storage</p>
              <p className='mt-[10px] ml-[15px]'>Your account storage is shared across Clikkle services, like Files,
              Ads, Campaign, E-sign, Host, C-mail, Projects and Pitch.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='/images/payementClud.svg' alt='Privacy Report Icon' className=" h-[230px] w-[305px] mr-[50px]" />
            </div>

           
          </div> }
          {  isMobile && 
         <div >
           
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]  font-medium  text-nowrap	">Account Storage</p>
            <img src="/images/payementClud.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-[1rem] mr-[-10px]"  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }
         {
          !isMobile ?
          <BorderLinearProgress variant="determinate" value={30}  style={{height:'6px' , width:'96%' , marginLeft:'1.5%'}} />
:
<BorderLinearProgress variant="determinate" value={30}  style={{height:'6px' , width:'90%' , marginLeft:'5%'}} />

         }
          <p className='mt-[9px] mb-[9px] md:ml-[1.5%] ml-[5%]'>43.75 GB used of 100 GB</p>
      

          <div className="border-t border-t-1 border-gray-300">
          
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage purchases</p>
          </div>
        </div>
        
      </div>

    
      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Subscriptions</p>
              <p className='mt-[10px] ml-[15px]'>Your recurring payments for subscription services..</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='images/subscriptionspeeker.svg' alt='Privacy Report Icon' className="w-full h-[230px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Subscriptions</p>
            <img src="images/subscriptionspeeker.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2" onClick={()=>{navigate("/payment/subscription/hr")}} >
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>
     {/* first */}
      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && 
          <div className="flex justify-between mb-[15px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Ads</p>
              <p className='mt-[10px] ml-[15px]'>Maximize your ad ROI with Clikkle's AI-powered platform. Real-time programmatic buying, dynamic retargeting, and hyper-targeted geofencing.</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px] ">
              <img src='/images/add.svg' alt='Privacy Report Icon' className="w-full h-[75px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]  font-medium text-nowrap	">Clikkle Ads</p>
            <img src='/images/add.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Maximize your ad ROI with Clikkle's AI-powered platform. Real-time programmatic buying, dynamic retargeting, and hyper-targeted geofencing.           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>
     {/* second */}

     <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle Host</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/host.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem] font-medium  text-nowrap	"> Clikkle Host</p>
            <img src='/images/host.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>


      {/* third svg */}

      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle Campaigns</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/campaing.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]  font-medium text-nowrap	"> Clikkle Campaigns</p>
            <img src='/images/campaing.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

       {/* fourth svg */}

       <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle Files</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/clicklfiles.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Files</p>
            <img src='/images/clicklfiles.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

           {/* fifth svg */}

           <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle E-sign</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/esign.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle E-sign</p>
            <img src='/images/esign.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>


      {/* sixth svg */}

      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle Pitch</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/pitch.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Pitch</p>
            <img src='/images/pitch.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

         {/* seventh svg */}

         <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] mb-[15px]">
          {  !isMobile && 
          <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]"> Clikkle C-mail</p>
              <p className='mt-[10px] ml-[15px]'>
              NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       

              </p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/cmail.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle C-mail</p>
            <img src='/images/cmail.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           NVMe SSD for Lightning-Fast Performance. Advanced DDoS Protection and WAF for Website Security. Scalable Resources for Cost-Efficient Hosting. 99.9% Uptime Guarantee       
           </p>
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

  {/* 8th svg */}
      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Projects</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/projectZ_1.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Subscriptions</p>
            <img src="images/subscriptionspeeker.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

        {/* 9th svg */}

        <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Launch</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/launcg.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Launch</p>
            <img src="images/launcg.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

            {/* 10th svg */}

            <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Hr</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/hr.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Hr</p>
            <img src="images/hr.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2"onClick={()=>{navigate("/payment/subscription/hr")}}>
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

          {/* 11th svg */}

          <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Tax</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/tax.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Tax</p>
            <img src="images/mtax.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

          {/* 12th svg */}

          <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Chat</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/chat.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Chat</p>
            <img src="images/chat.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

           {/* 13th svg */}

           <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Social</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/sociel.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Social</p>
            <img src="images/sociel.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

           {/* 14th svg */}

           <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Watsup</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/watsup.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Social</p>
            <img src="images/watsup.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

          {/* 15th svg */}

          <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clikkle Crew</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/crew.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Clikkle Crew</p>
            <img src="images/crew.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

           {/* 16th svg */}

           <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Swiprr</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/swiper.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	"> Swiprr</p>
            <img src="images/swiper.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

           {/* 16th svg */}

           <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">KeptUp</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/KeptUp.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	"> KeptUp</p>
            <img src="images/KeptUp.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>


   {/* 16th svg */}

   <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Hivrr</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/Hivrr.svg' alt='Privacy Report Icon' className="w-full h-[80px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	"> Hivrr</p>
            <img src="images/Hivrr.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>

         {/* 16th svg */}

   <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between mb-[20px]">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Clickle News</p>
              <p className='mt-[10px] ml-[15px]'>Discover our innovative project management solutions, powered by cutting-edge technology. 
              Streamline workflows, foster collaboration and achieve enhanced success in your projects effortlessly..</p>
            </div>
            <div className="w-[15%] flex items-center justify-end ml-[15px]">
              <img src='/images/clickklenews.svg' alt='Privacy Report Icon' className="w-full h-[150px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	"> Clickle News</p>
            <img src="images/clickklenews.svg" alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>




      
      <div className="rounded-lg mt-[22px] border" >
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%] mt-[25px]">
              <p className="text-[1.4rem] font-medium ml-[15px]">Reservations</p>
              <p className='mt-[10px] ml-[15px]'>Your past and upcoming reservations for flights, hotels and events
              using our services.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='images/reservetion.svg' alt='Privacy Report Icon' className="w-full h-[230px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex justify-between ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Reservations</p>
            <img  src='images/reservetion.svg' alt='Privacy Report Icon' className="  mt-[-20px] h-[4rem] p-2 "  />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Your past and upcoming reservations for flights, hotels and events
           using our services.           </p>
       
           
         </div> }

          <div className="border-t border-t-2">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage payment methods</p>
          </div>
        </div>
        
      </div>





  
      
    </Box>
   </>
  )
}

export default PaymentSubscrpition